<template>
  <v-container fluid class="pa-0 ma-0">
  <!-- <v-container fluid :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-0'"> -->

    <v-toolbar dense flat>
      <v-btn-toggle
        v-model="toggle_multiple"
        multiple
        dense
        tile
        borderless
        color="primary accent-3"
      >
        <v-btn
          v-model="search.today"
          text
          class="px-1"
          @click="btnClick('today')"
        >
          <v-icon
            small
            :color="search.today ? 'primary' : ''"
          >mdi-calendar</v-icon>
          <span>오늘</span>
        </v-btn>
        <v-btn
          v-model="search.star"
          text
          class="px-1"
          @click="btnClick('star')"
        >
          <v-icon
            small
            :color="search.star ? 'amber darken-1' : ''"
          >mdi-star</v-icon>
          <span>중요</span>
        </v-btn>
        <v-btn
          v-model="search.done"
          text
          class="px-1"
          @click="btnClick('done')"
        >
          <v-icon
            small
            :color="search.done ? 'orange darken-3' : ''"
          >mdi-gavel</v-icon>
          <span>종국</span>
        </v-btn>
      </v-btn-toggle>
      <!-- [정렬] 업데이트순 -->
      <div class="ml-2 hidden-xs-only" style="width: 150px">
        <v-select
          v-model="sort.default"
          :items="select.defaultSort"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectOrder"
        ></v-select>
      </div>
      <!-- 담당부서 -->
      <div class="hidden-xs-only" style="width:150px">
        <v-select
          v-model="search.ss2"
          :items="select.ss2"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss2')"
        ></v-select>
      </div>
      <!-- 관리상태 -->
      <div class="hidden-xs-only" style="width:120px">
        <v-select
          v-model="search.ss1"
          :items="select.ss1"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss1')"
        ></v-select>
      </div>
      <!-- 소송분야 > 상위 -->
      <div class="hidden-xs-only" style="width:140px">
        <v-select
          v-model="search.ss3"
          :items="select.ss3"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss3')"
        ></v-select>
      </div>
      <!-- 소송분야 > 하위 -->
      <div class="hidden-xs-only" style="width:150px">
        <v-select
          v-model="search.ss4"
          :items="select.ss4"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss4')"
        ></v-select>
      </div>

      <v-spacer></v-spacer>

      <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
      <!-- !! 검색 아이콘 처리 영역 start -->
      <v-menu
        v-model="searchMenu"
        :close-on-content-click="false"
        :nudge-width="50"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            text
            small
            class="hidden-xs-only"
          >
            <v-icon small>mdi-magnify</v-icon>
            검색
          </v-btn>
        </template>
        <v-card
          max-width="550"
          min-width="550"
          tile
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-toolbar
            dark
            dense
            flat
            color="primary"
            class="mb-0 pb-0"
          >
            <v-toolbar-items>
              <v-btn
                dark text class="orange--text text-h6 font-weight-bold" @click="searchPopBtn">검색</v-btn>
              <v-btn
                dark text class="white--text subheading" @click="searchMenu = false">취소</v-btn>
              <v-btn text icon
                @click="initVals"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                    >mdi-refresh</v-icon>
                  </template>
                  <span>초기화</span>
                </v-tooltip>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="mt-0 pt-0">
            <v-row
              align="center"
              justify="center"
              no-gutters
              class="ma-0 pa-0"
            >
              <!-- 검색어 검색 -->
              <v-col cols="12" xs="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <div style="width: 140px;">
                    <v-select
                      v-model="search.sf"
                      :items="select.sf"
                      label=""
                      item-value="value"
                      item-text="text"
                      hide-details
                      class="mt-2 ml-1 mr-3"
                      dense
                      style="font-size: 0.785rem !important"
                    ></v-select>
                  </div>
                  <v-text-field
                    v-model="search.sw"
                    label="검색"
                    append-icon="mdi-magnify"
                    maxlength="30"
                    clearble
                    class="mt-3 mr-5"
                    @keyup.enter="searchPopBtn"
                    @click:append="searchPopIcon"
                  ></v-text-field>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" class="ma-0 px-0 py-1">
                <v-row no-gutters>
                  <v-col cols="12" sm="4" class="ma-0 pa-0 text-start">
                    <v-select
                      v-model="search.ss7"
                      :items="select.ss7"
                      label=""
                      item-value="value"
                      item-text="text"
                      hide-details
                      class="mt-1 ml-1 mr-5"
                      dense
                      style="font-size: 0.875rem !important"
                      @change="selectChange('ss7')"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" class="ma-0 pa-0 text-center">
                    <v-select
                      v-model="search.ss5"
                      :items="select.ss5"
                      label=""
                      item-value="value"
                      item-text="text"
                      hide-details
                      class="mt-1 mx-5"
                      dense
                      style="font-size: 0.875rem !important"
                      @change="selectChange('ss5')"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" class="ma-0 pa-0 text-center">
                    <v-select
                      v-model="search.ss6"
                      :items="select.ss6"
                      label=""
                      item-value="value"
                      item-text="text"
                      hide-details
                      class="mt-1 mx-5"
                      dense
                      style="font-size: 0.875rem !important"
                      @change="selectChange('ss6')"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <!-- 관리그룹 처리 -->
              <v-col cols="12" xs="12" class="mt-5 mx-0 px-0 pt-2 pb-1">
                <v-row no-gutters>
                  <v-col cols="12" xs="12" class="mt-0">
                    <span class="text-subtitle-2 mt-0 mb-2 pb-0 grey--text">관리그룹</span>
                  </v-col>
                  <v-col cols="12"  xs="12"
                    v-for="i in 2"
                    :key="i"
                  >
                    <v-row
                      no-gutters
                      align="center"
                      justify="center"
                      class="mt-2 mb-3 mx-0 pa-0"
                    >
                      <v-col cols="12" sm="3" class="ma-0 pa-0 text-center">
                        <span class="text-body-2 text--primary">{{ mgTitle[`${i}`] }}</span>
                      </v-col>
                      <v-col cols="12" sm="9" class="ma-0 py-0 pr-2">
                        <v-select
                          v-model="search[`mgroup${i}`]"
                          :items="select[`mgroup${i}`]"
                          label=""
                          item-value="value"
                          item-text="text"
                          hide-details
                          class="mt-0 mb-2 mr-3"
                          dense
                          style="font-size: 0.875rem !important"
                          @change="selectChange(`mgroup${i}`)"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="mb-8"></div>
                  </v-col>
                </v-row>
              </v-col>
              <!--// 관리그룹 처리 -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      <!-- 검색 아이콘 처리 영역 end -->
    </v-toolbar>

    <v-toolbar dense flat color="grey lighten-2">
      <div class="text-left mr-0">
        <v-chip small color="primary">
          {{ totalItems }}
        </v-chip>
        <v-btn text icon
          @click="initVals"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
              >mdi-refresh</v-icon>
            </template>
            <span>초기화</span>
          </v-tooltip>
        </v-btn>
      </div>
      <div class="text-left hidden-xs-only">
        <v-chip
          v-for="(item, i) in searchKeywords"
          :key="i"
          small
          :close="!item.isEver"
          color="grey lighten-4"
          class="mr-1"
          @click:close="closeSearchKeyword(item)"
        >{{ item.text }}</v-chip>
      </div>
      <v-spacer></v-spacer>
      <div style="width: 60px">
        <v-select
          :items="itemsPerPageOptions"
          v-model="options.itemsPerPage"
          dense
          class="mt-6"
        ></v-select>
      </div>
    </v-toolbar>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      bottom
      color="light-blue"
    ></v-progress-linear>

    <v-card :elevation="0">
      <v-data-table
        :headers="headers"
        :items="datas"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-header
        hide-default-footer
        no-data-text="데이터가 없습니다"
        no-results-text="검색결과가 없습니다"
        loading-text="로딩중..."
      >
        <!-- 꼼수 : id 필드에 매칭시킨 item 을 가지고 각 row 마다 작업한다. v-show="$vuetify.breakpoint.lgAndUp" -->
        <template v-slot:[`item.id`]="{ item }">
          <v-list two-line class="pa-0 ma-0">
            <v-list-item class="float-left">
              <v-list-item-content>
                <v-list-item-title class="text-body-2">
                  <span
                    @click="viewArticle(item.id)"
                    style="cursor:pointer"
                    class="grey--text text--darken-1"
                  >
                    <v-icon small
                      v-if="item.isStar"
                      class="mr-1 pb-1"
                      color="amber darken-1"
                    >mdi-star</v-icon>
                    <v-icon
                      v-show="item.endDate && item.endType"
                      small
                      color="warning"
                      class="mr-1 pb-1"
                    >mdi-gavel</v-icon>
                    <span class="text-body-2 text--primary">
                      {{ item.caseName }}
                      <!-- 관할, 사건번호 -->
                      <span class="ml-1 mr-2" style="font-family: Sans-serif, 'NanumGothic';font-size: 0.925rem;font-weight: 550;">
                        {{ item.courtName }} {{ item.caseNum }}{{ item.manageNum ? `/${item.manageNum}` : '' }}
                      </span>
                      <v-chip
                        label outlined x-small class="mr-2 mb-1 px-1"
                        :color="item.status2 === '진행' ? 'primary' : 'black'"
                      >
                        <span class="text-caption font-weight-bold">{{ item.status2 }}</span>
                      </v-chip>
                    </span>
                    <span class="grey--text text--darken-1">
                      <span class="mr-1 primary--text text--lighten-1" style="font-weight: 500;">
                        {{ item._dcl_ }}<span class="grey--text text--darken-1">{{ item._dcl_ && item._dop_ ? `/${item._dop_}` : item._dop_ }}</span>
                      </span>
                      {{ item._groups_ ? ` - ${item._groups_}` : '' }}{{ item.teamName ? ` [${item.teamName}]` : ''}}
                    </span>
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle class="my-0 py-0">
                  <div
                    v-if="item.lwc && item.lwud && item.lwed"
                    class="pt-0 text--primary"
                    style="width: 80%;"
                  >
                    <v-icon x-small
                      v-if="item.lwc.isStar"
                      color="amber darken-1"
                    >mdi-star</v-icon>
                    {{ strDateFormat3(item.lwud) }}
                    <!-- @: 업무 -->
                    <template v-if="item.lwc.type === 22">
                      <v-chip
                        label outlined x-small class="mx-1 px-1"
                        :color="item.lwc.type === 21 ? (iconColors[item.lwc.subtype] || 'blue-grey darken-2') : 'blue-grey darken-2'"
                        style="font-size: 0.70rem;font-weight: 600;letter-spacing: 0.08em"
                      >
                        {{ item.lwc.gubun1 }}
                      </v-chip>
                      <span class="primary--text text--darken-1" :class="item.lwc.str2 ? 'mr-1' : ''">{{ item.lwc.str2 ? item.lwc.str2 : '' }}</span><!-- 업무자 -->
                      <span :class="item.lwc._place_time_ ? 'mr-1' : ''">{{ item.lwc._place_time_ }}</span><!-- 장소,시간 -->
                      <span v-show="item.lwc.str1" class="grey--text text--darken-3" :class="item.lwc.str1 ? 'mr-1' : ''"> - {{ cutString(item.lwc.str1, 120) }}</span><!-- 내용 -->
                      <span v-show="item.lwc.subtype === 1" class="primary--text">#{{ item.lwc.gubun2 }}</span><!-- 업무관리상태 -->
                    </template>
                    <!-- @: 기일 -->
                    <template v-else-if="item.lwc.type === 21 && item.lwc.subtype === 1">
                      <span class="mr-1 primary--text">{{ item.lwc.str1 }}</span><!-- 기일명 -->
                      <span class="primary--text" :class="item.lwc._place_time_ ? 'mr-1' : ''" v-html="item.lwc._place_time_"></span><!-- (장소 + 시간) -->
                      <span v-show="item.lwc.str2" class="error--text">{{ item.lwc.str2 }}</span><!-- 결과 -->
                      <span v-show="item.lwc._chk_end_" class="error--text">{{ item.lwc._chk_end_ }}</span><!-- 종국확인 -->
                      <span v-show="item.lwc.gubun2" class="primary--text" :class="item.lwc.str2 || item.lwc._chk_end_ ? 'ml-1' : ''">#{{ item.lwc.gubun2 }}</span><!-- 예정 -->
                    </template>
                    <!-- @: 기한 -->
                    <template v-else-if="item.lwc.type === 21 && item.lwc.subtype === 2">
                      <span class="mr-1 red--text text--darken-1">{{ item.lwc.str1 }}</span><!-- 기한명 -->
                      <span v-show="item.lwc.str2" class="primary--text">{{ item.lwc.str2 ? ` - ${item.lwc.str2}` : '' }}</span><!-- 대상자 -->
                    </template>
                    <!-- @: 송달 -->
                    <template v-else-if="item.lwc.type === 21 && item.lwc.subtype === 5">
                      <span class="mr-1" :class="`${textColors[item.lwc.subtype]}`">{{ item.lwc.str1 }}</span><!-- 제목 -->
                      <span v-show="item.lwc.date2 && item.lwc.str2" class="ml-1 error--text">{{ strDateFormat3(item.lwc.date2) }} {{ item.lwc.str2 }}</span>
                    </template>
                    <!-- @: 종국 -->
                    <template v-else-if="item.lwc.type === 21 && item.lwc.subtype === 8">
                      <span class="mr-1" :class="`${textColors[item.lwc.subtype]}`">{{ item.lwc.str1 }}{{ item.lwc.str2 ? ` - ${item.lwc.str2}` : '' }}</span><!-- 제목 & 상세결과 -->
                      <span v-show="item.lwc.view2" class="mr-1">{{ item.lwc.view2 }}</span>
                    </template>
                    <!-- @: [2022.9.20] 제출에 결과를 넣는다. -->
                    <template v-else-if="item.lwc.type === 21 && item.lwc.subtype === 4">
                      <span class="mr-1" :class="`${textColors[item.lwc.subtype]}`">{{ item.lwc.str1 }}</span><!-- 제목 -->
                      <span v-show="item.lwc.str2" class="ml-1 error--text">
                        {{ item.lwc.str2 }}
                      </span>
                    </template>
                    <!-- @: 그외: 명령, 공고, 접수, 내역 -->
                    <template v-else>
                      <span class="mr-1" :class="`${textColors[item.lwc.subtype]}`">{{ item.lwc.str1 }}</span><!-- 제목 -->
                    </template>
                  </div>
                  <div v-else></div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-data-table>

      <v-divider></v-divider>

      <div class="text-center pt-3 pb-3">
        <v-pagination
          v-model="options.page"
          :length="pages"
        ></v-pagination>
      </div>
    </v-card>

  </v-container>
</template>

<script>
import storage from '@/lib/storage'
import sleep from '@/lib/sleep'

// @: filters
import cutString from '@/filters/cutString'
import strDateFormat2 from '@/filters/strDateFormat2'
import strDateFormat3 from '@/filters/strDateFormat3'

// @: pdfmake list
import { pdfMake, pdfListStyle } from '@/lib/pdfmakeList'

// !![2021.4.30]lwc vuex 모듈 불러오기
import { createNamespacedHelpers } from 'vuex'

// !! lwc vuex 모듈
const _Lwc_ = createNamespacedHelpers('lwc')

export default {
  components: {
  },

  data: () => ({
    // 구분: 꼼수 - 헤더스에 별로 의미없는 1개만 매칭시킨다.
    headers: [
      {
        text: '',
        value: 'id',
        align: 'left',
        sortable: false
      }
    ],
    // 구분: 게시판용 변수들
    datas: [],
    totalItems: 0,
    itemsPerPageOptions: [ 15, 30, 50, 100 ],
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체
    params: { // 검색용 인자 객체
      draw: 0,
      where: {
        // ss1: 1 // 중요: 관리상태 초기값(진행)을 명시한다.
      },
      sort: [ 'lwed1' ], // 주의: lwed1 - 최신등록데이터의 최종업데이트일 주의: 정렬의 갯수만큼 초기값 지정해야 함
      order: [ 'DESC' ],
      offset: 0,
      limit: 1
    },
    // 구분: 초기값 설정이 필요한 변수들
    options: { // v-data-table 에 의해 자동으로 기본값이 들어있는 객체.
      itemsPerPage: 15, // 초기값을 지정했다. 기본값은 15라 매뉴얼에 나옴
      page: 1
    },
    toggle_multiple: [], // v-btn-toggle 의 초깃값이 없는 상태
    loading: false,
    // 구분: 검색에 필요한 변수들 - 초기화 필요
    search: {
      sf: 1, // 검색어 검색 select 의 선택된 필드값
      sw: '', // 검색어 검색 text box input 값
      mgroup1: '', // 관리그룹1 검색
      mgroup2: '', // 관리그룹2 검색
      today: false, // 오늘 검색 버튼 클릭값 - true/false
      star: false, // 중요 검색 버튼 클릭값 - true/false
      done: false, // 종국 검색 버튼 클릭값 - true/false
      ss1: '', // [관리상태] 셀렉트 검색 선택값
      ss2: '', // [담당부서] 셀렉트 검색 선택값
      ss3: '', // [소송분야>상위] 셀렉트 검색 선택값
      ss4: '', // [소송분야>하위] 셀렉트 검색 선택값
      ss5: '', // [심급] 셀렉트 검색 선택값
      ss6: '', // [진행방식] 셀렉트 검색 선택값
      ss7: '' // [기관] 셀렉트 검색 선택값
    },
    // 구분: 정렬에 필요한 변수들 - 초기화 필요
    sort: {
      default: 1 // [등록일순]기본정렬 매칭 - 정렬의 기본값은 select.sort.value 로 결정
    },
    // 구분: 셀렉트 객체들
    select: {
      sf: [ // 검색어 검색 필드 셀렉트
        { text: '당사자(사건기준)', value: 1 },
        { text: '사건(관리)번호', value: 2 },
        { text: '사건명', value: 3 },
        { text: '관할', value: 4 },
        { text: '재판(담당)부', value: 5 },
        { text: '본인(인명부기준)', value: 6 }
      ],
      ss1: [ // 관리상태 셀렉트 - 나머지는 카테고리에서 불러온다
        { text: '관리상태', value: '' }
      ],
      ss2: [ // 담당부서 셀렉트  - 나머지는 카테고리에서 불러온다
        { text: '담당부서', value: '' }
      ],
      ss3: [ // 소송분야 > 상위 셀렉트 - 나머지는 카테고리에서 불러온다
        { text: '소송분야', value: '' }
      ],
      ss4: [ // 소송분야 > 하위 셀렉트 - 나머지는 카테고리에서 불러온다
        { text: '상세분야', value: '' }
      ],
      ss5: [ // 심급
        { text: '심급', value: '' },
        { text: '1심', value: '1심' },
        { text: '2심', value: '2심' },
        { text: '3심', value: '3심' }
      ],
      ss6: [ // 진행방식
        { text: '진행방식', value: '' },
        { text: '전자', value: '전자' },
        { text: '일반', value: '일반' }
      ],
      ss7: [ // 기관
        { text: '기관', value: '' },
        { text: '법원', value: 1 },
        { text: '검찰', value: 2 },
        { text: '경찰', value: 3 },
        { text: '헌법재판소', value: 4 },
        { text: '기타', value: 5 }
      ],
      mgroup1: [ // 관리그룹 1
        { text: '선택', value: '' }
      ],
      mgroup2: [ // 관리그룹 2
        { text: '선택', value: '' }
      ],
      defaultSort: [ // 기본 정렬 셀렉트 - order 가 매칭되어있다.
        { text: '업데이트순', value: 1, field: 'lwed1', order: 'DESC' }, // 최신등록데이터 최종업데이트일순
        { text: '진행내용순', value: 2, field: 'lwud2', order: 'DESC' }, // 최신진행내용순
        { text: '기일/기한순', value: 3, field: 'lwud3', order: 'DESC' }, // 최신기일기한순
        { text: '업무순', value: 4, field: 'lwud4', order: 'DESC' } // 최신업무일순
        // { text: '사건등록일순', value: 5, field: 'createdAt', order: 'DESC' } // 등록일순
      ]
    },
    // 구분: 검색어 칩을 사용하는 것들의 배열 - 검색어 칩을 사용하는 요소를 명확히 알 수있다.
    // 타입: 정렬/검색 - sort/select
    // 이름: 요소이름 - sort.defalut
    // select: 비어있지 않은 경우 해당 셀렉트를 이용한다.
    // showSelect: 검색어 검색처럼 셀렉트를 직접 사용하진 않지만 텍스트를 보여줄 경우 사용(select: '' 인 경우에 주로 사용)
    // isEver: true 인 경우 항시 보여야 한다.
    // loading: true 인 경우 로딩시 처리된다
    useSearchKeywords: [
      { type: 'sort', name: 'default', select: 'defaultSort', showSelect: '', isEver: true, loading: true },
      { type: 'search', name: 'ss1', select: 'ss1', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss2', select: 'ss2', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss3', select: 'ss3', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss4', select: 'ss4', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss5', select: 'ss5', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss6', select: 'ss6', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss7', select: 'ss7', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'mgroup1', select: 'mgroup1', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'mgroup2', select: 'mgroup2', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'sw', select: '', showSelect: '', isEver: false, loading: false }
      // { type: 'search', name: 'sw', select: '', showSelect: 'sf', isEver: false, loading: false }
    ],
    // 구분: 검색어 칩 배열을 위한 변수
    searchKeywords: [],
    // 구분: 기타 변수들
    timeout: null, // delay() 에서 사용하는 변수 - 초기화 불필요
    // 구분: 관리그룹의 제목. 카테고리에서 패칭해서 채운다. 관리그룹이 늘어나면 더 늘려야 한다.
    mgTitle: {
      1: '',
      2: ''
    },
    // 구분: 검색 팝업을 위한 변수
    searchMenu: false,
    // 구분:중요: 소송분야 카테고리를 재패칭한 배열
    caseCates: [],
    // 구분: pdf
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 20, 20, 20, 20 ],
      content: []
    },
    // 구분: 아이콘 색상
    iconColors: {
      1: 'primary', // 기일
      2: 'red darken-1', // 기한
      3: 'green darken-2', // 명령
      4: 'brown darken-1', // 제출
      5: 'orange darken-3', // 송달
      6: 'blue-grey darken-2', // 공고
      7: 'blue-grey darken-2', // 접수
      8: 'blue-grey darken-2', // 종국
      9: 'blue-grey darken-2' // 내역 [2022.9.21 추가]
    },
    // 구분: 리스트에서 사용할 텍스트 색상
    textColors: {
      1: 'primary--text', // 기일
      2: 'red--text text--darken-1', // 기한
      3: 'green--text text--darken-2', // 명령
      4: 'brown--text text--darken-1', // 제출
      5: 'orange--text text--darken-3', // 송달
      6: 'blue-grey--text text--darken-2', // 공고
      7: 'blue-grey--text text--darken-2', // 접수
      8: 'blue-grey--text text--darken-2', // 종국
      9: 'blue-grey--text text--darken-2' // 내역 [2022.9.21 추가]
    }
  }),

  computed: {
    setOffset () {
      if (!this.options.page) return 0
      return (this.options.page - 1) * this.options.itemsPerPage
    },
    setLimit () {
      return this.options.itemsPerPage
    },
    pages () { // 페이지네이션 객체에 쓰인다
      if (this.options.itemsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.options.itemsPerPage)
    }
  },

  // 자동감지하고 리스트 재패칭 - 즉각적인 반영이 안될시 delay() 를 써야한다!
  watch: {
    '$route' (to, from) {
      // 내부등록시 리프레시 로직
      if (to.params.id) {
        const paramIds = to.params.id.split('-')
        if (paramIds.length > 1 && paramIds[1] === 'R') {
          // !! 등록
          // 등록시엔 '-R' 이 붙어온다. 이를 통해 리스트 초기화를 시킨다.
          this.initVals()
        } else if (paramIds[1] === 'E') {
          // !! 수정
          // 수정시엔 '-E'가 붙어온다. 이를 통해 리스트를 리프레시 시킨다.
          // 중요: 게시물 수정하고 리스트 리프레시 시켜야 함
          // 중요: 게시물의 부가데이터 즉, 진행내용, 업무를 수정한 후에도 리프레시 된다!
          // 단. 첫 페이지로 보내지 않고 검색도 그대로 유지한다
          this.delay(50)
        } else if (paramIds[0] === 'LR') {
          // 주의: 삭제
          // 리스트 리프레시로 파라미터가 날아온 경우 > 삭제의 경우
          this.options.page = 1 // 1페이지로 이동
          this.delay(50) // 리프레시 후
          this.$router.push(`/case`) // 리스트로 다시 보낸다.
        }
      }
    },
    // 중요: mounted 에서 리스트를 부를 필요없이 이것만으로 초기 로딩이 된다!
    options: {
      handler () {
        this.list()
      },
      deep: true
    },
    'options.itemsPerPage': { // 페이징 갯수 변경 자동감지
      handler () {
        this.options.page = 1 // 1페이지로 이동
        this.params.offset = 0 // 옵셋을 초기화 하지 않으면 에러
        this.delay(50)
      }
    }
  },

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }

    // 담당부서 select - 나의 팀목록 불러오기
    this.getTeams().then(teams => {
      if (teams && teams.length > 0) {
        this.select.ss2.push(...teams)
      }
    })

    // !![2021.5.11] 유저 권한을 패칭해서 vuex에 저장한다.
    this.saveMyAuths()

    // !! [2021.2.26 추가] 관리상태 카테고리 참고하여 ss1 셀렉트 만들기
    this.setCates('21').then(({ cates }) => {
      if (cates && cates.length > 0) {
        this.select.ss1.push(...cates.map(c => ({ text: c.gubun1, value: c.num })))
      }
    })

    // 관리그룹 select 만들기
    this.setCates('1').then(({ cates }) => {
      if (cates && cates.length > 0) {
        // 관리그룹의 갯수만큼 순회하면서 채운다. 현재 2개만 받을 수 있다.
        for (let cate of cates) {
          this.mgTitle[`${cate.num}`] = cate.gubun1
          const subs = cate.sub.split('|')
          this.select[`mgroup${cate.num}`].push(...subs.map(c => ({ text: c, value: c })))
        }
      }
    })

    // 소송분야(type1=2) 카테고리 패칭
    this.setCates('2').then(({ cates }) => {
      if (cates && cates.length > 0) {
        // 소송분야 상위 셀렉트 만들기
        this.select.ss3.push(...cates.map(c => ({ text: c.gubun1, value: c.gubun1 })))
        // !! 모든 소송분야의 카테고리 재패칭
        this.caseCates.push(...cates.map(c => ({ key: c.gubun1, subs: c.sub.split('|') })))
      }
    })

    // 사용안함: [2021.2.26] 관리상태 추가로 에러가 나서 - list() 로 옮김
    // 로딩시 등장해야할 검색어 칩을 찾아서 띄운다. 전돨되는 값은 배열이다.
    // this.$nextTick(() => {
    //   this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))
    // })
  },

  methods: {
    cutString,
    strDateFormat2,
    strDateFormat3,
    ..._Lwc_.mapActions([
      'setAllTeams',
      'setMyTeams',
      'setMyAuths'
    ]),
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    initialize () {
      this.list()
    },
    // 참고: watch 로 검색시 약간의 딜레이를 줘야 한다.
    delay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.list()
      }, ms)
    },
    // 중요: 등록시 검색등에 쓰이는 변수 초기화하고 리스트 재패칭
    async initVals () {
      this.doInit().then(() => {
        this.delay(100)
      })
    },
    // 변수 초기화 실행
    doInit () {
      return new Promise((resolve, reject) => {
        // 구분: params 로 백앤드로 전송되는 값
        this.params.draw = 0
        this.params.where = {
          // ss1: 1 // 중요: 관리상태 초기값(진행)을 명시한다!
        }
        this.params.sort = [ 'lwed1' ] // 주의: lwed1 - 최신등록데이터의 최종업데이트일
        this.params.order = [ 'DESC' ]
        this.params.offset = 0
        this.params.limit = 1

        // 구분: 검색용(v-model) 변수 - 초기값 설정이 필요
        this.search.sf = 1 // 검색어 검색의 선택된 필드값
        this.search.sw = '' // 검색어 검색의 text box input 값
        this.search.mgroup1 = '' // 관리그룹1 의 초기값
        this.search.mgroup2 = '' // 관리그룹2 의 초기값
        this.search.today = false // 오늘 검색 버튼 클릭값 - true/false
        this.search.star = false // 중요 검색 버튼 클릭값 - true/false
        this.search.done = false // 고문 검색 버튼 클릭값 - true/false
        this.search.ss1 = '' // 관리상태 셀렉트 선택값
        this.search.ss2 = '' // 담당부서 셀렉트 선택값
        this.search.ss3 = '' // 소송분야>상위 셀렉트 선택값
        this.search.ss4 = '' // 소송분야>하위 셀렉트 선택값
        this.search.ss5 = '' // 심급 셀렉트 선택값
        this.search.ss6 = '' // 진행방식 셀렉트 선택값
        this.search.ss7 = '' // 기관 셀렉트 선택값

        // 구분: 정렬 기본값 매칭(갯수만큼)
        this.sort.default = 1

        // 구분: 기타 초기값 설정이 필요한 변수들
        this.items = []
        this.totalItems = 0
        this.options.itemsPerPage = 15
        this.options.page = 1
        this.toggle_multiple = []
        this.loading = false

        // 구분: 검색어 칩 배열도 초기화
        this.searchKeywords = []

        // !! 그리고 초기 검색어 칩을 다시 띄움 - 없어도 되지만 부드럽게 보이기 위함
        this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))

        resolve(true)
      })
    },
    // !! 리스트 패칭
    async list () {
      try {
        if (this.loading) return
        this.loading = true

        this.params.draw++
        this.params.offset = this.setOffset
        this.params.limit = this.setLimit

        // !! 검색용 객체 만들기 - where 의 값이 없으면 삭제한다.
        const ws = this.params.where
        for (let key in ws) {
          if (!ws[key]) {
            delete ws[key]
          }
        }
        // console.log(this.params)

        // !! 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(500 - Math.floor(Math.random() * 300))

        const { data } = await this.$axios.get(`lawork/case/list`, { params: this.params })
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // 총 검색 갯수(이게 주어져야 페이징이 된다)
        this.totalItems = data.totalItems

        // 중요: 기본정렬의 종류에 따라서 보여줄 진행내용 데이터의 종류가 달라진다!
        // 백앤드로 보내진 sort[0] 을 분석해서 해당하는 정렬의 내용으로 진행내용을 만들어 리턴해 온다
        // 1) '업데이트순' -  최신등록데이터 최종업데이트일순 - lwc1 / lwed1
        // 2) '최신진행내용순' - 최신진행내용 업무일순 - lwc2 / lwud2
        // 3) '최신기일/기한순' - 최신기일기한 업무일순 - lwc3 / lwud3
        // 4) '최신업무순' - 최신업무 업무일순 - lwc4 / lwud4
        // 5) '등록일순' - 소송 등록일순 - lwc1 / createdAt
        // console.log(this.sort.default)
        // 참고: 데이터 반영
        this.datas = data.list
        if (data.list.length > 0) {
          data.list.forEach(item => {
            // !![2021.4.13] 당사자/상대방
            item._dcl_ = ''
            item._dop_ = ''
            if (item.clName) {
              item._dcl_ = `${item.clType ? `[${item.clType}]` : ''}${item.clName}`
            }
            if (item.opName) {
              item._dop_ = `${item.opType ? `[${item.opType}]` : ''}${item.opName}`
            }

            // !![2021.4.13] 그룹 패칭
            item._groups_ = Array.of(item.mgroup1, item.mgroup2).filter(m => m).join('|')

            // 중요: 진행내용, 업무 뷰 처리
            if (item.lwc && item.lwud && item.lwed) {
              item.lwc.view1 = ''
              item.lwc.view2 = ''

              // item.lwc.tm = item.lwc.date1.substr(11, 5) // 시-분 '11:30'
              // console.log(this.$moment(item.lwc.date1).format('HH:mm'))
              item.lwc.tm = this.$moment(item.lwc.date1).format('HH:mm') // 시-분 '11:30'

              // !! 업무/진행내용에 따라 구분해서 처리
              if (item.lwc.type === 22) {
                // @: 업무인 경우
                if (item.lwc.subtype !== 0) { // 등록같은 자동입력이 아닌 경우
                  // #1. 장소+시간 - item._place_time_
                  let tmpArr = []
                  if (item.lwc.str3) tmpArr.push(item.lwc.str3) // 장소
                  if (!item.lwc.noTime) tmpArr.push(item.lwc.tm) // 시간: 시간없음이 아닌 경우
                  if (tmpArr.length > 0) {
                    item.lwc._place_time_ = `(${tmpArr.join(' ')})`
                  }
                }
              } else if (item.lwc.type === 21) {
                // @: 진행내용
                if (item.lwc.subtype === 1) {
                  // @: 기일
                  // #1. 장소+시간 - item._place_time_
                  let tmpArr = []
                  if (item.lwc.str3) tmpArr.push(item.lwc.str3) // 장소
                  // 기일은 시간이 무조건 있으므로 시간없음의 개념이 없다.
                  tmpArr.push(`<b>${item.lwc.tm}</b>`)
                  if (tmpArr.length > 0) {
                    item.lwc._place_time_ = `(${tmpArr.join(' ')})`
                  }

                  // #2. 종국결과가 있는데 기일에 결과가 없는 경우 '종국확인' 문자열을 띄워야 한다.
                  // !! 기일인 경우 종국결과가 있으면 gubun3='종국' 이 들어있어야 한다!
                  item.lwc._chk_end_ = ''
                  if (item.lwc.gubun3 === '종국' && !item.lwc.str2) {
                    item.lwc._chk_end_ = '[종국확인]'
                  }
                  //
                } else if (item.lwc.subtype === 8) {
                  // @: 종국
                  // 종국결과 + 상세결과
                  item.lwc.view1 = `${item.lwc.str1}${item.lwc.str2 ? ` - ${item.lwc.str2}` : ''}`

                  // 결과평가|향후계획
                  let tmpArr = []
                  if (item.lwc.str3) tmpArr.push(item.lwc.str3)
                  if (item.lwc.str4) tmpArr.push(item.lwc.str4)
                  if (tmpArr.length > 0) {
                    item.lwc.view2 = ` [${tmpArr.join('|')}]`
                  }
                  //
                } else {
                  // @: 그외 .. 기한(2), 명령(3), 제출(4), 송달(5), 공고(6), 접수(7)
                  // item.lwc.view1 = item.lwc.str1
                  //
                }
              }
            }
          })
        }

        this.loading = false

        // 중요: [2021.2.26] 관리상태 추가로 에러발생 - 여기로 해도 되네?
        this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 상세페이지로 가기
    viewArticle (id) {
      // 고쳐: 이 부분은 게시판의 이전글/다음글을 위해 파라미터를 넘기는데.. 이게 필요없으면 빼야함.
      // !!중요: 로컬스토리지에 검색 파라미터를 저장한다!
      storage.set('listParams', this.params)

      // id 를 넘기자
      this.$router.push(`/case/${id}`)
    },
    // 구분: -- 검색처리 메소드 모음
    // 팝업 검색 버튼 클릭 이벤트 핸들러
    async searchPopBtn () {
      try {
        // 1) 검색어 검색 처리
        await this.searchWord()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[2021.9.30]팝업검색시 돋보기 아이콘을 클릭할때 검색처리 - 검색어가 없으면 아무것도 안함
    async searchPopIcon () {
      try {
        // 사용안함:검색어가 있는 경우만 searchPopBtn() 를 실행
        // if (this.search.sw) {
        //   await this.searchPopBtn()
        // }
        await this.searchPopBtn()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - true/false 검색 버튼 처리 함수
    async btnClick (elem) {
      try {
        this.search[elem] = !this.search[elem]
        this.params.where[elem] = this.search[elem]

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - 셀렉트 검색 처리 메소드
    // 주의: 소송분야 상세(ss3) 때문에 약간 변형됨
    async selectChange (elem) {
      try {
        this.params.where[elem] = this.search[elem]

        // !! 검색어 칩 처리 - 타입은 검색 - search && name = elem
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === elem)
        await this.setSearchKeywords(kw)

        // 주의: 소송리스트에서만 적용되는 부분
        if (elem === 'ss3') { // 소송분야 상위가 변경된 경우면
          await this.changeSs3()
        }
        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - 검색어 검색 처리 함수
    async searchWord () {
      try {
        if (this.search.sw.length > 0) { // 검색어가 있으면 파라미터에 넣고
          this.params.where.sf = this.search.sf
          this.params.where.sw = this.search.sw
        } else { // 없어도 일단 넣지만 값을 비운다. list() 에서 알아서 삭제된다.
          this.params.where.sf = ''
          this.params.where.sw = ''
        }

        // !! 검색어 칩 처리 - type = search  && name = sw
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === 'sw')
        await this.setSearchKeywords(kw)

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 정렬처리 메소드 모음 ----
    // this.select.{가변이름}Sort 로 여러개의 소트를 처리할 수 있다.
    // 여러개의 소트가 있어도 처리 가능하다
    // this.params.sort 를 초기화 하고 모든 소트를 새로 만든다.
    async selectOrder () {
      try {
        // 초기화
        this.params.sort = []
        this.params.order = []

        for (let key in this.sort) {
          const selectSortValue = this.sort[key]
          const field = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].field
          const order = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].order

          this.params.sort.push(field)
          this.params.order.push(order)

          // 검색어 칩 - type = sort(정렬)  && name = elem
          const kw = this.useSearchKeywords.find(k => k.type === 'sort' && k.name === key)
          await this.setSearchKeywords(kw)
        }

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: -- 검색어칩 처리 메소드 모음
    // 로딩시 보여줄 검색어 칩을 처리하는 메서드
    async loadSearchKeywords (kw) {
      try {
        if (!Array.isArray(kw)) throw new Error('잘못된 변수전달 방식입니다.')
        kw.forEach(async (k) => {
          await this.setSearchKeywords(k)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중요: 검색어 칩을 보여주는 처리를 하는 메서드
    async setSearchKeywords (kw) {
      try {
        // this.searchKeywords 배열에 등록될 객체의 뼈대
        let skw = { text: '', type: kw.type, name: kw.name, isEver: kw.isEver }

        // 기존 같은 타입과 이름의 배열이 있으면 삭제
        const index = this.searchKeywords.findIndex(k => k.type === kw.type && k.name === kw.name)
        if (index > -1) {
          this.searchKeywords.splice(index, 1)
        }

        // 현재값
        const currVal = this[kw.type][kw.name] || ''

        // select 가 있으면 select 에서 보여줄 text 를 가져온다
        if (kw.select) {
          const sel = this.select[kw.select].find(k => k.value === currVal)
          skw.text = (sel.value) ? sel.text : ''
        } else {
          // select 가 아닌 text 입력값은 현재값을 바로 매칭한다.
          // showSelect 가 지정된 경우 해당 셀렉트의 text 를 보여준다.
          if (kw.showSelect) {
            if (currVal) { // 값이 있어야 넣어준다
              skw.text = `${this.select[kw.showSelect].find(k => k.value === this.search[kw.showSelect]).text} - "${currVal}"`
            } else {
              skw.text = ''
            }
          } else {
            if (currVal) { // 값이 있어야 넣어준다
              skw.text = `"${currVal}"`
            } else {
              skw.text = ''
            }
          }
        }

        if (skw.text) {
          this.searchKeywords.push(skw)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 검색어 칩을 닫는 메서드
    async closeSearchKeyword (chip) {
      try {
        if (!chip.isEver) { // isEver = true 인 넘은 없앨 수 없다. false 인 경우만 처리
          const kw = this.useSearchKeywords.find(c => c.type === chip.type && c.name === chip.name)
          if (kw.select) {
            // 셀렉트 검색인 경우
            // this.select.sido = '' 처럼 셀렉트의 가장 처음값을 초기값으로 보고 변경시킨다.
            this[kw.type][kw.name] = this.select[kw.name][0].value
            await this.selectChange(kw.name)
          } else {
            //  검색어 검색인 경우
            if (kw.type === 'search' && kw.name === 'sw') {
              this[kw.type][kw.name] = ''
              await this.searchWord()
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 소송분야 상위 셀렉트 체인지 이벤트 핸들러
    // DB 에 재전송 하지 않게 미리 open() 함수에서 넣어둔 this.caseCates 배열을 이용한다.
    async changeSs3 () {
      try {
        // 소송분야 하위를 초기화 하고
        this.select.ss4 = [{ text: '상세분야', value: '' }]
        this.search.ss4 = ''
        // 중요: 넘기는 파라미터도 없앰
        this.params.where.ss4 = ''

        // 중요: 하위 셀렉트의 검색어 칩을 없앤다.
        // ss3 과 ss4 는 서로 연결되어있으므로 명시적으로 없애는 방식으로 해도 된다.
        const index = this.searchKeywords.findIndex(k => k.type === 'search' && k.name === 'ss4')
        if (index > -1) {
          this.searchKeywords.splice(index, 1)
        }

        // 상위 선택값이 있으면 하위 셀렉트를 만든다.
        if (this.search.ss3 && this.search.ss3.length > 0) {
          const subArr = this.caseCates.find(c => c.key === this.search.ss3).subs
          this.select.ss4.push(...subArr.map(c => ({ text: c, value: c })))
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 유틸리티 함수 모음 -------
    // 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 자신이 속한 팀을 가져온다
    // 기본팀은 필수, (관리자팀,물품구매팀,회의실관리팀은 제외)
    async getTeams () {
      try {
        const { data } = await this.$axios.get('lawork/case/getMyBasicTeamInfo')
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        // 중요: vuex lwc 모듈에 저장
        await this.setMyTeams(data.myTeams)

        // !![2021.4.30] 모든 팀을 vuex lwc 모듈에 저장
        await this.setAllTeams(data.allTeams)

        return data.teams
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 로그인 유저의 권한을 패칭해서 vuex 에 넣는다. 더불어 localStorage에도..
    async saveMyAuths () {
      try {
        const { data } = await this.$axios.get(`lawork/lwc/getStaffAuths`)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // console.log(data.auths)

        // 중요: vuex lwc 모듈에 저장
        await this.setMyAuths(data.auths)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 리스트 제너레이터
    // 중요: /src/lib/pdfmakeList.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgen () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = '사건관리 - 소송 목록'

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfListStyle
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          // { text: '중요', style: 'tableHeader', alignment: 'center' },
          { text: '관할', style: 'tableHeader', alignment: 'center' },
          { text: '사건(관리)번호', style: 'tableHeader', alignment: 'center' },
          { text: '사건명', style: 'tableHeader', alignment: 'center' },
          { text: '의뢰인', style: 'tableHeader', alignment: 'center' },
          { text: '상대방', style: 'tableHeader', alignment: 'center' },
          { text: '재판부', style: 'tableHeader', alignment: 'center' },
          { text: '분야', style: 'tableHeader', alignment: 'center' },
          // { text: '심급', style: 'tableHeader', alignment: 'center' },
          { text: '종국', style: 'tableHeader', alignment: 'center' },
          // { text: '최종진행내용', style: 'tableHeader', alignment: 'center' },
          { text: '관리그룹', style: 'tableHeader', alignment: 'center' },
          { text: '상태', style: 'tableHeader', alignment: 'center' },
          { text: '담당부서', style: 'tableHeader', alignment: 'center' }
          // { text: '등록일', style: 'tableHeader', alignment: 'center' }
        ])

        this.datas.forEach(d => {
          let endInfo = '' // 종국결과
          if (d.endDate && d.endType) {
            endInfo = `${this.$moment(d.endDate).format('YY.MM.DD')}${d.endType ? ` ${d.endType}` : ''}`
          }
          // 사용안함:[2021.4.19] 최종진행내용 제거
          // let ljc = '' // 최종진행내용
          // if (d.lwc && d.lwud && d.lwed) {
          //   // !! 자문과 달리 view2 는 제외.. 너무 길어짐
          //   ljc = `${this.$moment(d.lwud).format('YY.MM.DD')} ${d.lwc.view1}`
          //   // ljc = `${this.$moment(d.lwud).format('YY.MM.DD')} ${d.lwc.view1} ${d.lwc.view2}`
          // }

          // 주의: 아래 push 에 넣는 배열항목을 제거하거나 늘린 후 아래 content 의 table.widths 배열도 맞춰야 함
          tBody.push([
            // d.isStar ? '★' : '',
            `${d.isStar ? '★ ' : ''}${d.courtName}`,
            `${d.caseNum}${d.manageNum ? `(${d.manageNum})` : ''}`,
            d.caseName,
            `${d.clType ? `[${d.clType}]` : ''}${d.clName}`,
            `${d.opType ? `[${d.opType}]` : ''}${d.opName}`,
            d.jepanbu,
            `${d.gubun1}${d.gubun2 ? `|${d.gubun2}` : ''}`,
            endInfo,
            // ljc, 사용안함:[2021.4.19] 최종진행내용 제거
            [ d.mgroup1, d.mgroup2 ].filter(g => g).join(' | '),
            d.status2,
            d.teamName
          ])
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfDoc.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
